<script setup lang="ts">
  const config = useState<{title:string, icon:string, rntPage:string}>("pageconfig")
</script>
<template>
    <div>
        <Header></Header>
        <div class="h-max bg-slate-500 pt-2">
            <PageHeader icon="folder" :title="config.title">
                <div class="flex space-x-2">

                    <NuxtLink :to="config.rntPage"
                        class="  text-white  border border-slate-100 hover:text-white hover:rings-3 ring-slate-50 px-5 py-2 rounded-lg">
                        Done</NuxtLink>

                </div>
            </PageHeader>


            <div class="     border border-slate-400 p-6  ">

                <div class="">
                    <div class="flex justify-between mb-2 px-2">
                        
                    </div>
                    <slot />

                </div>

            </div>




        </div>
        <Footer></Footer>
    </div>
</template>
<style lang="scss">
body {
    background-color: #cdcdcf;
}
</style>